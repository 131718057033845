import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import NextImage from 'next/image';
import ImageLoader from './image-loader';
import LoadingSkeleton from 'components/loading-skeleton';
import LoadWhenVisible from 'components/load-when-visible';
import NoImage from 'components/image-loader/image/no-image';
import ImageDescription from '../image-description';
import { useThemeContext } from 'contexts/theme';
import { ThemeNames } from 'types/themes';
import { isServerSide } from 'utils/host-config';

interface Props {
  item: {
    id: string;
    image: string;
    alt: string | null;
    imageDesc?: string;
    isImageReady?: boolean;
    addedAt?: Date;
  };
  onClick?: Dispatch<SetStateAction<any>>;
  isVisible?: boolean;
  isLightbox?: boolean;
  className?: string;
  testId?: string;
  isCrawler?: boolean;
  width?: number;
  isGalleryImage?: boolean;
  isListingCardImage?: boolean;
  loadLowQualityFirst?: boolean;
}

export default function Image({ item: { id, image, alt, imageDesc, isImageReady, addedAt }, className, onClick = () => {}, isVisible: initialVisibility, isLightbox, testId, isCrawler, width, isGalleryImage, isListingCardImage, loadLowQualityFirst = false }: Props) {
  const [isVisible, setIsVisible] = useState(isLightbox || initialVisibility);
  const { themeName } = useThemeContext();
  const isExpTheme = themeName !== ThemeNames.ZOOCASA;
  const [imageSrc, setImageSrc] = useState(loadLowQualityFirst ? image + '?w=400' : image);

  useEffect(() => {
    if (loadLowQualityFirst) {
      // When page loads, initially serve low quality image, on user mouse move/tapping the screen on mobile load the full quality image
      const onUserMouseMove = () => {
        setImageSrc(image);
      };
      window.addEventListener('mousemove', onUserMouseMove, { once: true });
      window.addEventListener('touchstart', onUserMouseMove, { once: true });
    }
  }, [image, loadLowQualityFirst]);

  if (isImageReady === false) {
    return <>
      <NoImage isRecent={addedAt && (new Date().getTime() - addedAt.getTime()) / (1000 * 60 * 60) < 48} isExpTheme={isExpTheme}/>
    </>;
  } else if (!isLightbox) { // many usages including pre-con photo gallery
    return (
      <NextImage
        src={imageSrc}
        id={id}
        alt={alt || ''}
        className={className}
        onClick={onClick}
        fill
        sizes='(max-width: 639px) 480px, 640px'
        style={{ objectFit:'cover' }}
        placeholder={initialVisibility ? undefined : 'blur'}
        blurDataURL={getShimmerPlaceHolder()}
        priority={isCrawler || initialVisibility}
        loader={props => ImageLoader({ ...props, windowWidth: width, isGalleryImage, isListingCardImage })}
        data-testid={testId}
        loading={initialVisibility ? 'eager' : 'lazy'}
      />
    );
  } else { // usages include lightbox single photo gallery
    return (
      <LoadWhenVisible onValueChange={() => setIsVisible(true)}>
        {isVisible ?
          <>
            <NextImage
              src={image}
              id={id}
              alt={alt || ''}
              className={className}
              onClick={onClick}
              data-testid={testId}
              loading="lazy"
              fill
            />
            {imageDesc && <ImageDescription description={imageDesc} />}
          </>
          :
          <div id={id} style={{ height: '100%', width: '100%' }}>
            <LoadingSkeleton height="100%" width="100%" className={className} />;
          </div>
        }
      </LoadWhenVisible>
    );
  }
}

const getShimmerPlaceHolder = () => {
  const toBase64 = (encodedString: string) => {
    if (isServerSide()) {
      return Buffer.from(encodedString).toString('base64');
    } else {
      return window.btoa(encodedString);
    }
  };

  const shimmer = (w: number, h: number) => `
  <svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <linearGradient id="g">
        <stop stop-color="#f6f7f9" offset="0%" />
        <stop stop-color="#e9ebee" offset="20%" />
        <stop stop-color="#f6f7f9" offset="40%" />
        <stop stop-color="#f6f7f9" offset="100%" />
      </linearGradient>
    </defs>
    <rect width="${w}" height="${h}" fill="#f6f7f9" />
    <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
    <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite" />
  </svg>`;

  return `data:image/svg+xml;base64,${toBase64(shimmer(20, 20))}`;

};
